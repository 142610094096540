<template>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit, validate, reset, invalid }">
        <v-form>
            <v-card>
                <v-card-title>
                    <span class="headline">User Form</span>
                </v-card-title>
                <v-card-text>
                    <v-progress-linear v-if="formLoading" indeterminate />
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <ValidationProvider v-slot="{ errors }" name="Name" rules="required">
                                    <v-text-field v-model="formData.name" :error-messages="errors" :label="$t('name')"
                                        :disabled="formData.id > 0" />
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <ValidationProvider v-slot="{ errors }" name="Email" rules="required|email">
                                    <v-text-field :error-messages="errors" v-model="formData.email" :label="$t('email')"
                                        :disabled="formData.id > 0" />
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <ValidationProvider v-slot="{ errors }" name="User Type" rules="required">
                                    <v-select :error-messages="errors" :items="user_types" :disabled="formLoading"
                                        :loading="formLoading" item-text="value" item-value="id"
                                        v-model="formData.user_type" label="User Type" @change="userTypeChange"
                                        required />
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="
                                formData.user_type !== 'admin' &&
                                formData.user_type !== ''
                            ">
                                <ApolloQuery :query="require('@/graphql/client/clientsDropdown.gql')
                                    ">
                                    <template v-slot="{
                                        result: { loading, error, data },
                                    }">
                                        <v-select v-if="data" :items="data.allClients" :disabled="formLoading"
                                            :loading="formLoading" item-text="name" item-value="id"
                                            v-model="formData.client" label="Client" return-object clearable required />
                                        <v-select v-else label="Loading Clients" />
                                    </template>
                                </ApolloQuery>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="isSuperAdmin && !hasSuperAdminFormData">
                                <v-checkbox v-model="formData.switch_user" label="Switch User Enabled"></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="!formData.id">
                                <v-checkbox v-model="formData.send_email" label="Send Welcome Email"
                                    value="y"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeFormModal" :disabled="formLoading">{{ $t('close')
                        }}</v-btn>
                    <v-btn v-if="formData.id" color="primary darken-1" text :loading="formLoading"
                        :disabled="formLoading" @click="handleSubmit(updateUser())">{{ $t('save') }}</v-btn>
                    <v-btn v-else color="primary darken-1" text :disabled="formLoading" :loading="formLoading"
                        @click="handleSubmit(createUser())">{{ $t('create') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </ValidationObserver>
</template>
<script>
import { required, email } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'
setInteractionMode('eager')
extend('email', email)
extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})
export default {
    name: 'UserForm',
    props: {
        user: {
            type: Object,
            default: () => ({
                id: '',
                name: '',
                email: '',
                user_type: '',
                client: {},
                switch_user: false,
            }),
        },
    },
    data: () => ({
        error: null,
        showpass: false,
        mutation: '',
        formLoading: false,
        user_types: [
            {
                id: 'admin',
                value: 'Administrator',
            },
            {
                id: 'client_admin',
                value: 'Company Administrator',
            },
            {
                id: 'client_user',
                value: 'Company User',
            },
        ],
        defaultForm: {
            id: '',
            name: '',
            email: '',
            user_type: '',
            client: { id: '' },
            send_email: 'y',
        },
        formData: {},
    }),
    created() {
        this.formData = { ...this.defaultForm, ...this.user }
    },
    computed: {
        isSuperAdmin() {
            return this.$store.getters.userForPortalAccess.user_type === 'admin'
        },
        hasSuperAdminFormData() {
            return this.formData && this.formData.user_type === 'admin'
        },
    },
    methods: {
        userTypeChange(event) {
            if (event === 'admin') {
                this.formData.client = {}
            }
        },
        updateUser() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return
                } else {
                    this.mutation = require('@/graphql/user/updateUser.gql')
                    const vars = {
                        id: this.formData.id,
                        user_type: this.formData.user_type,
                        client: this.formData.client
                            ? this.formData.client.id
                            : undefined,
                        switch_user: this.formData.switch_user,
                    }
                    this.saveForm(vars)
                }
            })
        },
        createUser() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return
                } else {
                    this.mutation = require('@/graphql/user/createUser.gql')
                    const vars = {
                        id: this.formData.id,
                        user_type: this.formData.user_type,
                        client: this.formData.client.id,
                        name: this.formData.name,
                        email: this.formData.email,
                        send_email: this.formData.send_email,
                        switch_user: this.formData.switch_user,
                    }
                    this.saveForm(vars)
                }
            })
        },
        closeFormModal() {
            this.$refs.observer.reset()
            this.$emit('user-form-closed')
        },
        saveForm(vars) {
            if (!this.$refs.observer.validate()) {
                return
            }
            if (this.formData.client == null) {
                this.formData.client = {}
            }
            this.formLoading = true
            this.$apollo
                .mutate({
                    // Query
                    mutation: this.mutation,
                    // Parameters
                    variables: vars,
                })
                .then(data => {
                    // report success
                    this.$emit('user-form-closed', data)
                    this.$emit('user-form-saved', data)
                    this.formLoading = false
                })
                .catch(error => {
                    // Error
                    console.error(error)
                    // update user....
                    this.formLoading = false
                })
        },
    },
    watch: {
        user: {
            handler: function () {
                this.formData = { ...this.defaultForm, ...this.user }
            },
            deep: true,
        },
    },
}
</script>
